import React, { useState } from "react";
import { useParams } from 'react-router-dom'; // Import useParams for dynamic route
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PublicIcon from '@mui/icons-material/Public';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import StandardButton from "../components/standardButton";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import StarIcon from '@mui/icons-material/Star';
import { Chip, Fade, Slider } from "@mui/material";
import CountryFlag from "../components/countryFlag";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StartupsCard from "../components/startupCard";
import ArticleCard from "../components/articleCard";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";

export default function Startup() {
  const { id } = useParams(); // Get the startup ID from the URL
  console.log(id);
  const [startupData, setStartupData] = useState(null);
  const [saved, setSaved] = useState(false);
  const [integrationTiming, setValue] = useState(3);

  // Fetch data
  const handleDataFetchSuccess = (data) => {
    setStartupData(data.data); // Adjust for the actual JSON structure
  };

  const handleSaveToggle = () => {
    setSaved(!saved);
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="bg-black p-5 h-full min-h-[100vh] flex flex-col gap-10 max-w-[calc(100vw-265px)]">
      <FlexibleDataFetcher
        endpoint={`innovations/${id}`} // Dynamic ID from URL
        onSuccess={handleDataFetchSuccess}
        onError={(err) => console.error('Failed to fetch startup data:', err)}
      />

      {startupData ? (
        <>
          <div className="flex justify-between gap-2">
            <h1 className="text-5xl font-semibold">{startupData.name || "No Name"}</h1>
            <div className="flex gap-2">
              {saved ? (
                <Fade in={saved} timeout={300}>
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: "rgb(var(--global-color-primary))",
                      fontSize: "40px",
                    }}
                    onClick={() => handleSaveToggle()}
                  />
                </Fade>
              ) : (
                <Fade in={!saved} timeout={300}>
                  <BookmarkOutlinedIcon
                    sx={{
                      color: "rgb(var(--global-color-primary))",
                      fontSize: "40px",
                    }}
                    onClick={() => handleSaveToggle()}
                  />
                </Fade>
              )}
              {startupData.linkedin_url && (
                <a href={startupData.linkedin_url} target="_blank" rel="noreferrer">
                  <LinkedInIcon sx={{ color: "rgb(var(--global-color-primary))", fontSize: "40px" }} />
                </a>
              )}
              {startupData.website && (
                <a href={startupData.website} target="_blank" rel="noreferrer">
                  <PublicIcon sx={{ color: "rgb(var(--global-color-primary))", fontSize: "40px" }} />
                </a>
              )}
            </div>
          </div>

          <div className="flex gap-10 flex-wrap">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <img
                  src={startupData.logo_url || startupData.fixed_logo_url || "https://dev.retailhub.ai/default-logo.png"}
                  alt={startupData.name || "No Logo"}
                  className="w-72 rounded-2xl"
                />
                <div className="flex gap-2">
                  <span className="font-semibold">Company type:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{startupData.company_type?.name || "N/A"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Business model:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{startupData.business_model?.name || "N/A"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Status:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{startupData.account_status?.name || "N/A"}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Target markets:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{startupData.clients || "N/A"}</span>
                </div>
              </div>
              <StandardButton text="Chat now" variant="filled" />
              <div className="flex gap-2">
                <span className="font-bold">Download PDF</span>
                <PictureAsPdfIcon sx={{ color: "rgb(var(--global-color-primary))" }} />
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex gap-10 flex-wrap">
                <div className="flex flex-col gap-2">
                  <span className="font-semibold">Rating:</span>
                  <div>
                    {Array.from({ length: startupData.rating || 0 }, (_, index) => (
                      <StarIcon key={index} sx={{ color: "rgb(var(--global-color-primary))" }} />
                    ))}
                  </div>
                  <span className="font-semibold">Tags:</span>
                  <div className="flex flex-wrap w-full gap-2 items-center">
                    {startupData.taxonomies?.tags.length > 0 ? (
                      startupData.taxonomies.tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag.name || "N/A"}
                          variant="outlined"
                          sx={{
                            fontFamily: "Urbanist, sans-serif",
                            border: "1px solid rgb(255, 255, 255, .3)",
                            color: "white",
                          }}
                        />
                      ))
                    ) : (
                      <span>No tags available</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-16 w-16">
                      <NorthEastIcon sx={{ color: "black" }} />
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold opacity-50">Total funding:</span>
                      <div className="font-bold text-3xl">
                        $ {startupData.total_funding ? startupData.total_funding.toLocaleString() : "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold">Founded:</span>
                      <span className="text-[rgb(var(--global-color-primary))]">{startupData.founding_year || "N/A"}</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-semibold">Country:</span>
                      <CountryFlag countryCode={startupData.country?.name || "US"} size={"30px"} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <h2 className="text-4xl">Description</h2>
                <p>{startupData.description.replace(/<\/?[^>]+(>|$)/g, "") || "No description available"}</p>
              </div>
              <div className="flex flex-col gap-2">
                <h2 className="text-4xl">Solutions, products, and services</h2>
                <p>{startupData.solutions?.replace(/<\/?[^>]+(>|$)/g, "") || "No information available"}</p>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <h2 className="text-4xl">Sectors of competence</h2>
              {/* Example sector icons */}
              <div className="flex justify-between gap-2">
                <div className="flex flex-col gap-2 justify-start items-center">
                  <SportsHandballIcon sx={{ color: "rgb(var(--global-color-primary))", width: "62px", height: "62px" }} />
                  <span>Sport</span>
                </div>
                <div className="flex flex-col gap-2 justify-start items-center">
                  <LocalPizzaIcon sx={{ color: "rgb(var(--global-color-primary))", width: "62px", height: "62px" }} />
                  <span>Food</span>
                </div>
                <div className="flex flex-col gap-2 justify-start items-center">
                  <AccountBalanceIcon sx={{ color: "rgb(var(--global-color-primary))", width: "62px", height: "62px" }} />
                  <span>Banking</span>
                </div>
              </div>
              <h2 className="text-4xl">Integration timing</h2>
              <div className="flex gap-4 items-center">
                <span className="min-w-fit">1 Month</span>
                <Slider
                  defaultValue={3}
                  value={integrationTiming}
                  min={1}
                  max={12}
                  sx={{ color: "rgb(var(--global-color-primary))" }}
                  onChange={handleSliderChange}
                />
                <span className="min-w-fit">1 Year</span>
              </div>
              <span className="flex justify-center">{integrationTiming} months</span>
            </div>
          </div>
        </>
      ) : (
        <p>Loading startup details...</p>
      )}
    </div>
  );
}
