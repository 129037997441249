import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';

const existingRoles = [
  {
    id: 0,
    name: "Restricted User"
  },
  {
    id: 1,
    name: "Admin"
  },
  {
    id: 2,
    name: "Super Admin"
  }
]

export default function NewRoleDialog() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [copiedRole, setCopiedRole] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value)
        break;
      case 'copiedRole':
        setCopiedRole(event.target.value)
        break;
      default:
        setName(event.target.value)
        break;
    }
  }

  return (
    <React.Fragment>
      <StandardButton 
        text="Add new role / permission"
        width="300px" 
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new role</h1>
            <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
              <StandardTextfield label='Name' value={name} onChange={(e) => {handleChange(e, 'name')}} />
              <StandardSelect 
                items={existingRoles} 
                value={copiedRole}
                label={"Copy from role"}
                onChange={(e) => {handleChange(e, 'copiedRole')}}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={handleClose} autoFocus={true} text="Submit" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}