// #region Using Data grid

import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import StandardButton from './standardButton';

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: 12 },
  { id: 6, lastName: 'Melisandre', firstName: 'Camille', age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  { id: 10, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 11, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 12, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 13, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 14, lastName: 'Targaryen', firstName: 'Daenerys', age: 12 },
  { id: 15, lastName: 'Melisandre', firstName: 'Camille', age: 150 },
  { id: 16, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 17, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 18, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

const paginationModel = { page: 0, pageSize: 15 };

export default function PaginatedTable({height, items, header, perPage, minHeight, hideFooter}) {
  const [selectedRows, setSelection] = React.useState([])

  const handleSelection = (rowsIds, details) => {
    // console.log(rowsIds, details)
    let selectedRowsToAdd = []
    if(!!items) {
      for (let i = 0; i < rowsIds.length; i++) {
        selectedRowsToAdd = [...selectedRowsToAdd, items.find((it) => it.id === rowsIds[i])]
      }
      setSelection(selectedRowsToAdd)
    }
    // console.log(selectedRows)
  }

  return (
    <Paper sx={{ height: !!height ? height : "100%", width: '100%', maxWidth: '100%', maxHeight: "100%", overflow: "hidden", position: 'relative', minHeight: !!minHeight ? minHeight : 'auto' }}>
      <DataGrid
        rows={!!items ? items : rows}
        columns={!!header ? header : columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={!!perPage ? perPage : [15, 25, 100]}
        checkboxSelection
        hideFooter={!!hideFooter ? hideFooter : false}
        dis
        onRowSelectionModelChange={handleSelection}
        sx={{ 
          border: '1px solid rgb(var(--global-color-primary))', 
          backgroundColor: "#191919", 
          color: 'white', 
          fill: 'white',
          fontFamily: "Urbanist, sans-serif",
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#191919',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold'
            }
          },
          '& .MuiDataGrid-filler': {
            backgroundColor: '#191919'
          },
          '& .MuiTablePagination-toolbar': {
            color: 'white'
          },
          '& .MuiSvgIcon-root': {
            color: 'white'
          },
          '& .MuiDataGrid-scrollbarFiller': {
            backgroundColor: '#191919'
          },
          '& .MuiDataGrid-overlay': {
            backgroundColor: '#191919'
          },
          '& .MuiList-root': {
            backgroundColor: "#191919",
            color: 'white'
          },
          '& .MuiListItemIcon-root': {
            color: "white"
          },
        }}
      />
      {
        selectedRows.length > 0 &&
        <div className='absolute w-full flex justify-between items-center bottom-0 left-0 bg-[rgb(var(--global-color-primary))] rounded-2xl p-2 px-5'>
          <span className='font-bold'>{selectedRows.length} selected</span>
          <div className='flex gap-5 items-center'>
            <StandardButton text="Delete" variant="filled" />
            <StandardButton text="Copy" variant="filled" />
            <StandardButton text="Export" variant="filled" />
          </div>
        </div>
      }
    </Paper>
  );
}
//#endregion



// #region Using Table components

// import * as React from 'react';
// import { Checkbox, TableRow, TableHead, TableBody, Table, TableContainer, TableCell, TablePagination, Paper } from '@mui/material';

// const columns = [
//   { id: 'name', label: 'Name', minWidth: 170 },
//   { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
//   {
//     id: 'population',
//     label: 'Population',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'size',
//     label: 'Size\u00a0(km\u00b2)',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toLocaleString('en-US'),
//   },
//   {
//     id: 'density',
//     label: 'Density',
//     minWidth: 170,
//     align: 'right',
//     format: (value) => value.toFixed(2),
//   },
// ];

// function createData(id, name, code, population, size) {
//   const density = population / size;
//   const selected = false;
//   return { id, name, code, population, size, density, selected };
// }

// const rows = [
//   createData(0, 'India', 'IN', 1324171354, 3287263),
//   createData(1, 'China', 'CN', 1403500365, 9596961),
//   createData(2, 'Italy', 'IT', 60483973, 301340),
//   createData(3, 'United States', 'US', 327167434, 9833520),
//   createData(4, 'Canada', 'CA', 37602103, 9984670),
//   createData(5, 'Australia', 'AU', 25475400, 7692024),
//   createData(6, 'Germany', 'DE', 83019200, 357578),
//   createData(7, 'Ireland', 'IE', 4857000, 70273),
//   createData(8, 'Mexico', 'MX', 126577691, 1972550),
//   createData(9, 'Japan', 'JP', 126317000, 377973),
//   createData(10, 'France', 'FR', 67022000, 640679),
//   createData(11, 'United Kingdom', 'GB', 67545757, 242495),
//   createData(12, 'Russia', 'RU', 146793744, 17098246),
//   createData(13, 'Nigeria', 'NG', 200962417, 923768),
//   createData(14, 'Brazil', 'BR', 210147125, 8515767),
//   createData(15, 'India', 'IN', 1324171354, 3287263),
//   createData(16, 'China', 'CN', 1403500365, 9596961),
//   createData(17, 'Italy', 'IT', 60483973, 301340),
//   createData(18, 'United States', 'US', 327167434, 9833520),
//   createData(19, 'Canada', 'CA', 37602103, 9984670),
//   createData(20, 'Australia', 'AU', 25475400, 7692024),
//   createData(21, 'Germany', 'DE', 83019200, 357578),
//   createData(22, 'Ireland', 'IE', 4857000, 70273),
//   createData(23, 'Mexico', 'MX', 126577691, 1972550),
//   createData(24, 'Japan', 'JP', 126317000, 377973),
//   createData(25, 'France', 'FR', 67022000, 640679),
//   createData(26, 'United Kingdom', 'GB', 67545757, 242495),
//   createData(27, 'Russia', 'RU', 146793744, 17098246),
//   createData(28, 'Nigeria', 'NG', 200962417, 923768),
//   createData(29, 'Brazil', 'BR', 210147125, 8515767),
// ];

// export default function PaginatedTable() {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [selectAll, setSelectAll] = React.useState(false);
//   const [selectedRows, setSelection] = React.useState([]);


//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };
  
//   const handleSelectAll = () => {
//     setSelection(rows.map((r) => {return {...r, selected: true}}))
//     setSelectAll(!selectAll)
//   };
  
//   const handleSelection = (event) => {
//     if(!!event.selected && event.selected) {
//       event.selected = false
//       setSelection([...selectedRows.filter(r => r.id != event.id)])
//     } else {
//       event.selected = true
//       if(selectedRows.length > 0) {
//         setSelection([...selectedRows.filter(r => r.id != event.id), event])
//       } else {
//         setSelection([event])
//       }
//     }
//     // console.log(selectedRows)
//   };

//   return (
//     <Paper sx={{ width: '100%', height: "100%", maxHeight: "100%", overflow: 'hidden', backgroundColor: "#191919", border: "1px solid rgb(var(--global-color-primary))" }}>
//       <TableContainer sx={{ height: "100%", maxHeight: "calc(100% - 54px)"}} >
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{backgroundColor: "#191919", color: "white", fontWeight: "bold", fontFamily: "Urbanist, sans-serif"}}>
//                 <Checkbox checked={selectAll} onClick={handleSelectAll} sx={{color: "white"}}/>
//               </TableCell>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={column.align}
//                   style={{ minWidth: column.minWidth }}
//                   sx={{backgroundColor: "#191919", color: "white", fontWeight: "bold", fontFamily: "Urbanist, sans-serif"}}
//                 >
//                   {column.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody sx={{
//             '&:nth-of-type(odd)': {
//               backgroundColor: "#000"
//             }
//           }}>
//             {rows
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map(row => 
//                 <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
//                   <TableCell key={row.id} sx={{backgroundColor: "#191919", color: "white", fontFamily: "Urbanist, sans-serif"}}>
//                     <Checkbox checked={row.selected} onClick={() => {handleSelection(row)}} key={row.id} sx={{color: "white"}}/>
//                   </TableCell>
//                   {columns.map((column) => {
//                     const value = row[column.id];
//                     return (
//                       <TableCell 
//                         key={column.id} 
//                         align={column.align}
//                         sx={{backgroundColor: "#191919", color: "white", fontFamily: "Urbanist, sans-serif"}}
//                       >
//                         {column.format && typeof value === 'number'
//                           ? column.format(value)
//                           : value}
//                       </TableCell>
//                     );
//                   })}
//                 </TableRow>
//               )
//             }
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 100]}
//         component="div"
//         count={rows.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         sx={{
//           backgroundColor: "#191919", 
//           color: "white",
//           borderTop: "1px solid rgb(var(--global-color-primary))",
//           '& .Mui-disabled': {
//             color: 'rgb(255, 255, 255, .5)'
//           },
//         }}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   );
// }
//#endregion
