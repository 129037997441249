import React from "react";
import PaginatedTable from '../components/paginatedTable'
import NewDiscoveryChip from "../components/newDiscoveryChip";
import SectorFilterDialog from "../components/sectorFilterDialog";
import { Button } from "@mui/material";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import NewUserDialog from "../components/newUserDialog";

const items = [
  { id: 1, type: 'Standard', founder: 'Jon Jones', email: 'jon.jones@gmail.com', emailVerified: false, brand: 'Jon', sectors: 35, area: 35, rate: 3 },
  { id: 2, type: 'Standard', founder: 'Cersei Jones', email: 'cersei.jones@gmail.com', emailVerified: true, brand: 'Cersei', sectors: 42, area: 42, rate: 4 },
  { id: 3, type: 'Standard', founder: 'Jaime Jones', email: 'jaime.jones@gmail.com', emailVerified: true, brand: 'Jaime', sectors: 45, area: 45, rate: 4 },
  { id: 4, type: 'Standard', founder: 'Arya Jones', email: 'arya.jones@gmail.com', emailVerified: true, brand: 'Arya', sectors: 16, area: 16, rate: 1 },
  { id: 5, type: 'Standard', founder: 'Daenerys Jones', email: 'daenerys.jones@gmail.com', emailVerified: true, brand: 'Daenerys', sectors: 12, area: 12, rate: 1 },
  { id: 6, type: 'Standard', founder: 'Camille Jones', email: 'camille.jones@gmail.com', emailVerified: true, brand: 'Camille', sectors: 150, area: 150, rate: 5 },
  { id: 7, type: 'Standard', founder: 'Ferrara Jones', email: 'ferrara.jones@gmail.com', emailVerified: true, brand: 'Ferrara', sectors: 44, area: 44, rate: 4 },
  { id: 8, type: 'Standard', founder: 'Rossini Jones', email: 'rossini.jones@gmail.com', emailVerified: true, brand: 'Rossini', sectors: 36, area: 36, rate: 3 },
  { id: 9, type: 'Standard', founder: 'Harvey Jones', email: 'harvey.jones@gmail.com', emailVerified: false, brand: 'Harvey', sectors: 65, area: 65, rate: 5 },
  { id: 10, type: 'Standard', founder: 'Jon Jones', email: 'jon.jones@gmail.com', emailVerified: false, brand: 'Jon', sectors: 35, area: 35, rate: 3 },
  { id: 11, type: 'Standard', founder: 'Cersei Jones', email: 'cersei.jones@gmail.com', emailVerified: false, brand: 'Cersei', sectors: 42, area: 42, rate: 4 },
  { id: 12, type: 'Standard', founder: 'Jaime Jones', email: 'jaime.jones@gmail.com', emailVerified: true, brand: 'Jaime', sectors: 45, area: 45, rate: 4 },
  { id: 13, type: 'Standard', founder: 'Arya Jones', email: 'arya.jones@gmail.com', emailVerified: true, brand: 'Arya', sectors: 16, area: 16, rate: 1 },
  { id: 14, type: 'Standard', founder: 'Daenerys Jones', email: 'daenerys.jones@gmail.com', emailVerified: true, brand: 'Daenerys', sectors: 12, area: 12, rate: 1 },
  { id: 15, type: 'Standard', founder: 'Camille Jones', email: 'camille.jones@gmail.com', emailVerified: true, brand: 'Camille', sectors: 150, area: 150, rate: 5 },
  { id: 16, type: 'Standard', founder: 'Ferrara Jones', email: 'ferrara.jones@gmail.com', emailVerified: true, brand: 'Ferrara', sectors: 44, area: 44, rate: 4 },
  { id: 17, type: 'Standard', founder: 'Rossini Jones', email: 'rossini.jones@gmail.com', emailVerified: true, brand: 'Rossini', sectors: 36, area: 36, rate: 3 },
  { id: 18, type: 'Standard', founder: 'Harvey Jones', email: 'harvey.jones@gmail.com', emailVerified: false, brand: 'Harvey', sectors: 65, area: 65, rate: 5 },
];

export default function UserList() {
  const [rows, setRows] = React.useState(items);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const header = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'brand', headerName: 'Brand', width: 130 },
    { field: 'type', headerName: 'Startup Type', width: 130 },
    { field: 'founder', headerName: 'Founder', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { 
      field: 'emailVerified', 
      headerName: 'Email verified',
      width: 120,
    },
    {
      field: 'sectors',
      headerName: 'Sectors',
      type: 'number',
      width: 120,
    },
    {
      field: 'area',
      headerName: 'Areas',
      type: 'number',
      width: 100,
    },
    {
      field: 'rate',
      headerName: 'Rates',
      type: 'number',
      width: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (!!editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          <NewDiscoveryChip />
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">User List</h1>
            <NewUserDialog />
          </div>
          <div className="flex flex-wrap gap-4">
            <SectorFilterDialog />
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Status
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Location
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Year
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Tags
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Available to chat
            </Button>
            <Button 
              variant="outlined"
              sx={{
                fontFamily: "Urbanist, sans-serif", 
                border: "1px solid rgb(255, 255, 255, .3)", 
                color: "rgb(255, 255, 255, .3)"
              }}
            >
              Sort by
            </Button>
          </div>
          <PaginatedTable header={header} items={items} />
        </div>
      </div>
    </div>
  )
}