import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';


// Configura l'intercettore per aggiungere il token
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Recupera il token dal localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Aggiungi il token all'header Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // Gestisci eventuali errori
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
