import * as React from "react";
import klarnaLogo from '../assets/startups/klarna-logo.png'
import { Checkbox } from '@mui/material';
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";

const user = {
  id: 0,
  firstname: 'Pietro',
  lastname: 'La Pietra',
  role: [
    {
      id: 0,
      name: 'Admin',
      selected: true
    },
    {
      id: 1,
      name: 'Super Admin',
      selected: false
    }
  ],
  department: [
    {
      id: 0,
      name: 'Admin',
      selected: true
    }
  ],
  image: klarnaLogo,
  phone: "3276779220",
  email: "fabio.r@fortitude.digital",
  faEnabled: true
}

export default function AccountInformation() {
  const [enabled, setEnabled] = React.useState(true);
  const [role, setRole] = React.useState("");
  const [department, setDepartment] = React.useState("");

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handleChangeDepartment = (event) => {
    setDepartment(event.target.value);
  };
  
  const handleFAToggle = (event) => {
    setEnabled(!enabled);
    user.faEnabled = !user.faEnabled
  };
  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[100vh] flex p-5">
        <div className="flex flex-col gap-4 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
          <h1 className="text-6xl font-medium">Account Information</h1>
          <StandardTextfield 
            label='Firstname' 
            value={user.firstname} 
          />
          <StandardTextfield 
            label='Lastname' 
            value={user.lastname} 
          />
          <StandardSelect 
            label="Role" 
            value={role} 
            items={user.role} 
            onChange={handleChangeRole} 
          />
          <StandardSelect 
            label="Department" 
            value={department} 
            items={user.department} 
            onChange={handleChangeDepartment} 
          />
          <StandardTextfield 
            label='Phone' 
            value={user.phone} 
          />
          <StandardTextfield 
            label='Email' 
            value={user.email} 
          />
          <div className="flex gap-2 items-center">
            <Checkbox checked={user.faEnabled} onClick={handleFAToggle} sx={{color: "white"}}/>
            <span>Enable 2FA</span>
          </div>
          <div className="flex flex-col gap-4 justify-center items-center">
            <span><span className="text-[rgb(var(--global-color-primary))]">Change</span> the password</span>
            <div className="flex flex-wrap gap-5">
              <StandardButton variant="outlined" text="Reset" width="300px" />
              <StandardButton variant="filled" text="Save" width="300px" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}