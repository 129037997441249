import React from "react";
import PaginatedTable from '../components/paginatedTable';
import NewDiscoveryChip from "../components/newDiscoveryChip";
import StandardButton from "../components/standardButton";
import { TextField } from "@mui/material";

const items = [
  { id: 1, logo: '', type: 'Standard', founder: 'Jon Jones', email: 'jon.jones@gmail.com', emailVerified: false, brand: 'Jon', sectors: 35, area: 35, rate: 3 },
  { id: 2, logo: '', type: 'Standard', founder: 'Cersei Jones', email: 'cersei.jones@gmail.com', emailVerified: true, brand: 'Cersei', sectors: 42, area: 42, rate: 4 },
];

export default function CompanyMembers() {
  const [rows, setRows] = React.useState(items);
  const [searchTerm, setSearchTerm] = React.useState("");

  const header = [
    { field: 'logo', headerName: 'Logo', width: 100, sortable: false },
    { field: 'brand', headerName: 'Brand', width: 130, sortable: false },
    { field: 'type', headerName: 'Startup Type', width: 130, sortable: false },
    { field: 'founder', headerName: 'Founder', width: 130, sortable: false },
    { field: 'email', headerName: 'Email', width: 200, sortable: false },
    { field: 'emailVerified', headerName: 'Email Verified', width: 120, sortable: false },
    { field: 'sectors', headerName: 'Sectors', width: 120, sortable: false },
    { field: 'area', headerName: 'Areas', width: 100, sortable: false },
    { field: 'rate', headerName: 'Rates', width: 100, sortable: false },
  ];

  const filteredRows = rows.filter((row) =>
    row.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.founder.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          <NewDiscoveryChip />
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Company Members</h1>
            <StandardButton text="Add new startup" width="300px" variant="filled" />
          </div>
          <div className="flex justify-end w-full">
            <TextField
              label="Search"
              variant="outlined"
              sx={{
                input: { color: "white" },
                "& label": { color: "rgb(255, 255, 255, .7)" },
                "& label.Mui-focused": { color: "rgb(255, 255, 255)" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgb(255, 255, 255, .3)" },
                  "&:hover fieldset": { borderColor: "rgb(255, 255, 255, .7)" },
                  "&.Mui-focused fieldset": { borderColor: "rgb(255, 255, 255)" },
                },
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <PaginatedTable
            header={header}
            items={filteredRows}
            sx={{
              tableLayout: "fixed", // Fix the table layout
              wordWrap: "break-word", // Ensure long words are wrapped
              "& .MuiDataGrid-root": {
                overflowX: "hidden", // Prevent horizontal scrolling
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
