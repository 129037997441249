import React, { useState } from 'react';
import axios from 'axios';

function Auth() {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleLogin = async () => {
    try {
      // Effettua la richiesta per ottenere il token
      const response = await axios.post('https://dev.retailhub.ai/api/v4/login', {
        email: 'usera@asdf.asdf',
        password: 'asdf'
      });

      // Salva il token nel localStorage
      const token = response.data.token;
      localStorage.setItem('token', token);

      // Imposta il successo del login
      setSuccess(true);
      console.log('Login avvenuto con successo, token:', token);
    } catch (err) {
      setError('Errore durante il login');
      console.error('Errore:', err);
    }
  };

  return (
    <div>
      <h2>Autenticazione</h2>
      <button onClick={handleLogin}>Ottieni Token</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>Token salvato nel localStorage!</p>}
    </div>
  );
}

export default Auth;
