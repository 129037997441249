import React from "react";
import PaginatedTable from '../components/paginatedTable'
import NewDiscoveryChip from "../components/newDiscoveryChip";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

const items = [
  { id: 1, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 2, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 3, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 4, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 5, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 6, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 7, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 8, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 9, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 10, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 11, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 12, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 13, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 14, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 15, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 16, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 17, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
  { id: 18, name: 'Data Boutique', title: 'AI, How to use it correctly', email: "anna.retailhub@gmail.com", date: '10 Oct 2023, 10:00 a.m.', link: 'www.retailhub.com',},
];

function LinkColumn(params) {
  return <a href={`https://${params.value}`} className="text-[rgb(var(--global-color-primary))]" target="_blank" rel="noreferrer">{params.value}</a>
}
export default function Articles() {
  const [rows, setRows] = React.useState(items);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const header = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'email', headerName: 'Contact Email', width: 200 },
    { field: 'date', headerName: 'Date', width: 200 },
    { 
      field: 'link', 
      headerName: 'Link', 
      width: 200,
      type: 'custom',
      renderCell: LinkColumn,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (!!editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full">
          <NewDiscoveryChip />
          <div className="flex items-center w-full">
            <h1 className="text-6xl font-medium">Articles</h1>
          </div>
          <PaginatedTable header={header} items={items} />
        </div>
      </div>
    </div>
  )
}