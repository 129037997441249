import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';

const currencies = [
  {
    id: 0,
    name: "EUR"
  },
  {
    id: 1,
    name: "USD"
  },
  {
    id: 2,
    name: "GBP"
  }
]

export default function NewPriceDialog() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [currency, setCurrency] = React.useState([]);
  const [comments, setComments] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value)
        break;
      case 'amount':
        setAmount(event.target.value)
        break;
      case 'currency':
        setCurrency(event.target.value)
        break;
      case 'comments':
        setComments(event.target.value)
        break;
      default:
        setName(event.target.value)
        break;
    }
  }

  return (
    <React.Fragment>
      <StandardButton 
        text="Add new price"
        width="300px" 
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new payment plan</h1>
            <div className='flex flex-col gap-4 w-full'>
              <StandardTextfield label='Name' value={name} onChange={(e) => {handleChange(e, 'name')}} />
              <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
                <StandardTextfield label='Amount' value={amount} onChange={(e) => {handleChange(e, 'amount')}} />
                <StandardSelect 
                  items={currencies} 
                  value={currency} 
                  label={"Currency"}
                  onChange={(e) => {handleChange(e, 'currency')}}
                />
              </div>
              <StandardTextfield label='Comments' value={comments} onChange={(e) => {handleChange(e, 'comments')}} />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={handleClose} autoFocus={true} text="Submit" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}