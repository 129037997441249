import React from 'react';
import NorthEastIcon from '@mui/icons-material/NorthEast'
import NewDiscoveryChip from '../components/newDiscoveryChip';

export default function Dashboard() {
  return (
    <div className='flex flex-col gap-5 p-5 h-full'>
      <div className='flex gap-5 h-full'>
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Standard startup</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                1234
              </div>
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Demo startup</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                1111
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex gap-5 h-full'>
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Active status</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                123
              </div>
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col gap-5 p-5 bg-[#191919] rounded-2xl'>
          <NewDiscoveryChip />
          <h1 className="text-6xl font-medium">Inactive startup</h1>
          <div className="flex gap-2">
            <div className="p-2 flex justify-center items-center rounded-lg bg-[rgb(var(--global-color-primary))] h-44 w-44">
              <NorthEastIcon sx={{color: "black", fontSize: "8rem"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-semibold p-2 px-5 bg-green-900 text-green-500 flex rounded-full w-fit">+129%</span>
              <div className="font-bold text-9xl">
                12334
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}