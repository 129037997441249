import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';

export default function NewTeamUserDialog() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstname] = React.useState("");
  const [lastName, setLastname] = React.useState("");
  const [role, setRole] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'email':
        setEmail(event.target.value)
        break;
      case 'firstname':
        setFirstname(event.target.value)
        break;
      case 'lastname':
        setLastname(event.target.value)
        break;
      case 'country':
        setCountry(event.target.value)
        break;
      case 'city':
        setCity(event.target.value)
        break;
      case 'role':
        setRole(event.target.value)
        break;
      case 'password':
        setPassword(event.target.value)
        break;
      case 'phone':
        setPhone(event.target.value)
        break;
      default:
        setCity(event.target.value)
        break;
    }
  }

  return (
    <React.Fragment>
      <StandardButton 
        text="Add new user"
        width="300px" 
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new user</h1>
            <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
              <StandardTextfield label='Firstname' value={firstName} onChange={(e) => {handleChange(e, 'firstname')}} />
              <StandardTextfield label='Lastname' value={lastName} onChange={(e) => {handleChange(e, 'lastname')}} />
              <StandardTextfield label='Country' value={country} onChange={(e) => {handleChange(e, 'country')}} />
              <StandardTextfield label='City' value={city} onChange={(e) => {handleChange(e, 'city')}} />
              <StandardTextfield label='Email' value={email} onChange={(e) => {handleChange(e, 'email')}} />
              <StandardTextfield label='Role' value={role} onChange={(e) => {handleChange(e, 'role')}} />
              <StandardTextfield label='Password' value={password} onChange={(e) => {handleChange(e, 'password')}} />
              <StandardTextfield label='Phone' value={phone} onChange={(e) => {handleChange(e, 'phone')}} />              
            </div>            
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={handleClose} autoFocus={true} text="Submit" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}