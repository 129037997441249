import React from "react"
import ReactCountryFlag from "react-country-flag"

function CountryFlag({countryCode, size}) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <ReactCountryFlag 
        countryCode={countryCode} 
        svg 
        style={{width: !!size ? size : "50px", height: !!size ? size : "50px"}}
      />
    </div>
  )
} export default CountryFlag