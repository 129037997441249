import * as React from "react";
import klarnaLogo from '../assets/startups/klarna-logo.png'
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";

const company = {
  id: 0,
  country: {
    id: 1,
    fullname: "Italy",
    shortCode: "IT"
  },
  city: 'Milan',
  legalName: 'Klarna S.p.a.',
  brandName: 'Klarna',
  linkedin: "www.linkedin.com/klarna",
  website: "www.retailhub.com",
  phone: "3276779220",
  description: "",
  tags: [
    {
      id: 0,
      name: 'Unicorn',
      selected: true
    }
  ],
  image: klarnaLogo,
  sector: "Payment method"
}
const countries = [
  {
    id: 0,
    name: "United States of America",
    shortCode: "US"
  },
  {
    id: 1,
    name: "Sweden",
    shortCode: "SE"
  },
  {
    id: 2,
    name: "Italy",
    shortCode: "IT"
  }
]
const selectableTags = [
  {
    id: 0,
    name: 'Unicorn',
    variant: 'filled'
  },
  {
    id: 1,
    name: 'CRM',
    variant: 'outlined'
  },
  {
    id: 2,
    name: 'Ecommerce',
    variant: 'outlined'
  },
  {
    id: 3,
    name: 'Financial',
    variant: 'outlined'
  }
]

export default function CompanyDetails() {
  const [country, setCountry] = React.useState("");
  const [tags, setTags] = React.useState([]);


  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  
  const handleChangeTags = (event) => {
    setTags(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[100vh] flex p-5">
        <div className="flex flex-col gap-4 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
          <div className="flex items-center justify-between">
            <h1 className="text-6xl font-medium">Company Details</h1>
            <img src={company.image} alt="Company logo" className="w-20 h-20 object-cover rounded-2xl" />
          </div>
          <div className="flex gap-4">
            <StandardSelect 
              label="Country" 
              value={country} 
              items={countries} 
              onChange={handleChangeCountry} 
            />
            <StandardTextfield 
              label='City' 
              value={company.city} 
            />
          </div>
          <div className="flex gap-4">
            <StandardTextfield 
              label='Legal name' 
              value={company.legalName} 
            />
            <StandardTextfield 
              label='Brand name' 
              value={company.brandName} 
            />
          </div>
          <div className="flex gap-4">
            <StandardTextfield 
              label='Linkedin company page' 
              value={company.linkedin} 
            />
            <StandardTextfield 
              label='Phone' 
              value={company.phone} 
            />
            <StandardTextfield 
              label='Website url' 
              value={company.website} 
            />
          </div>
          <StandardTextfield 
            label='Company description' 
            value={company.description} 
            multiline={true}
            minRows={4}
          />
          <StandardTextfield 
            label='Sectors of interest' 
            value={company.sector} 
          />
          <StandardSelect 
            label="Tags" 
            value={tags} 
            items={selectableTags} 
            multiple={true}
            onChange={handleChangeTags} 
          />
          <div className="flex justify-center items-center flex-wrap gap-5">
            <StandardButton variant="outlined" text="Reset" width="300px" />
            <StandardButton variant="filled" text="Save" width="300px" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}