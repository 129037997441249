import * as React from "react";
import klarnaLogo from '../assets/startups/klarna-logo.png'
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";

const company = {
  id: 0,
  country: "Italy",
  city: 'Milan',
  address: 'Via Montenapoleone, 12',
  zipCode: '29027',
  vatNumber: 'IT2081349027',
  legalName: 'Klarna S.P.A.',
  linkedin: "www.linkedin.com/klarna",
  website: "www.retailhub.com",
  phone: "3276779220",
  description: "",
  tags: [
    {
      id: 0,
      name: 'Unicorn',
      selected: true
    }
  ],
  image: klarnaLogo,
  sector: "Payment method",
  subscriptionType: "Paid",
  plan: {
    id: 0,
    duration: "year",
    price: 28142.99,
    name: "Single-Private_Person",
    expirationDate: "Jun 25, 2025",
  }
}
const selectablePayments = [
  {
    id: 0,
    name: 'Bonifico'
  },
  {
    id: 1,
    name: 'Fattura'
  }
]

export default function BillingDetails() {
  const [selectedPayment, setPayment] = React.useState("");
  
  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[100vh] flex p-5">
        <div className="flex flex-col gap-10 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
          <h1 className="text-6xl font-medium">Billing Details</h1> 
          <div className="flex gap-5 flex-wrap justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-lg font-semibold">Subscription details</span>
              <span>Your account is on: <span className="text-[rgb(var(--global-color-primary))]">{company.subscriptionType} subscription</span></span>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <span className="font-semibold">Plan name:</span>
                <span className="text-[rgb(var(--global-color-primary))]">{company.plan.name}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold">Plan Expiration Date:</span>
                <span className="text-[rgb(var(--global-color-primary))]">{company.plan.expirationDate}</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <span className="font-semibold">Plan Duration:</span>
                <span className="text-[rgb(var(--global-color-primary))]">{company.plan.duration}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold">Plan Price:</span>
                <span className="text-[rgb(var(--global-color-primary))]">{company.plan.price} €</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-lg font-semibold">Your next billing date is:</span>
              <span className="text-[rgb(var(--global-color-primary))]">{company.plan.expirationDate}</span>
            </div>
          </div>
          <div className="text-center">Never miss a month. We will only use your backup payment method if we can't process your main one.</div>
          <StandardTextfield 
            label='Legal name' 
            value={company.legalName} 
          />
          <div className="flex gap-4">
            <StandardTextfield 
              label='Country' 
              value={company.country} 
            />
            <StandardTextfield 
              label='City' 
              value={company.city} 
            />
            <StandardTextfield 
              label='Address' 
              value={company.address} 
            />
          </div>
          <div className="flex gap-4">
            <StandardTextfield 
              label='Vat number' 
              value={company.vatNumber} 
            />
            <StandardTextfield 
              label='Post / Zip code' 
              value={company.zipCode} 
            />
          </div>
          <div className="bg-[rgb(67,68,69)] rounded-2xl flex gap-5 items-center p-5">
            <div className="basis-1/3">
              <StandardSelect 
                label="Payment Requests/Receipts" 
                value={selectedPayment} 
                items={selectablePayments} 
                onChange={handleChangePayment} 
              />
            </div>
            <div className="flex flex-col gap-2 basis-1/3">
              <span className="text-lg font-semibold">{company.plan.name}</span>
              <div>
                <div className="flex gap-2 font-semibold">
                  <span>Payment method:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{!!selectedPayment ? selectedPayment : selectablePayments[0].name }</span>
                </div>
                <div className="flex gap-2 font-semibold">
                  <span>Plan valid for:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">1 {company.plan.duration}</span>
                </div>
                <div className="flex gap-2 font-semibold">
                  <span>Price:</span>
                  <span className="text-[rgb(var(--global-color-primary))]">{company.plan.price}</span>
                </div>
              </div>
            </div>
            <StandardButton variant="filled" text="View receipt" className={"basis-1/3"}/>
          </div>
          <div className="flex justify-center items-center flex-wrap gap-5">
            <StandardButton variant="outlined" text="Reset" width="300px" />
            <StandardButton variant="filled" text="Save for payment" width="300px" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}