import React from "react"
import { Chip } from "@mui/material"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"

export default function NewDiscoveryChip() {
  return (
    <div className="p-1 flex gap-5 items-center rounded-full bg-[rgb(var(--global-color-primary),.3)] w-fit border border-solid border-[rgb(var(--global-color-primary))]">
      <div className="flex gap-2 items-center">
        <Chip 
          label="New"
          variant="filled"
          sx={{
            fontFamily: "Urbanist, sans-serif", 
            border: "1px solid rgb(255, 255, 255, .3)", 
            backgroundColor: "rgb(var(--global-color-primary))",
            color: "black"
          }}
        />
        <span>Discover</span>
      </div>
      <ArrowRightAltIcon />
    </div>
  )
}