import React, { useState } from "react";
import FlexibleDataFetcher from '../components/flexibileDataFetcher';
import StartupsCard from "../components/startupCard";
import SectorFilterDialog from "../components/sectorFilterDialog";
import { Button } from "@mui/material";
import NewDiscoveryChip from "../components/newDiscoveryChip";

function Startups() {
  const [startupsData, setStartupsData] = useState([]); // Holds the startups data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Handle successful data fetching
  const handleDataFetchSuccess = (data) => {
    if (data && data.data) {
      setStartupsData(data.data);
    } else {
      setStartupsData([]);
    }
    setLoading(false);
  };

  // Handle data fetching error
  const handleDataFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  // Map through startupsData and create a list of StartupCards
  const listStartups = startupsData.map((s) => (
    <StartupsCard
      key={s.id}
      name={s.name}
      description={!!s.description ? s.description.replace(/<\/?[^>]+(>|$)/g, "") : "No description available"} // Remove HTML tags from description
      logo={s.logo_url || "https://dev.retailhub.ai/default-logo.png"} // Default logo if none
      countryCode={s.country?.iso || "Unknown"} // Fallback if country name is missing
      tags={s.taxonomies?.tags?.length > 0 
        ? s.taxonomies.tags.map((tag, index) => ({
            id: index,
            label: tag.name || "N/A",
            variant: "outlined"
          }))
        : [{ id: 0, label: "No tags", variant: "outlined" }]} // Fallback if no tags available
      link={`/startups/startup/${s.id}`} 
    />
  ));

  return (
    <div className="bg-black p-5 h-full min-h-[100vh] flex flex-col gap-10">
      {/* Filters and header */}
      <div className="flex flex-col gap-2">
        <NewDiscoveryChip />
        <h1 className="text-6xl font-medium">Startups</h1>
        <div className="flex flex-wrap gap-4">
          <SectorFilterDialog />
          <Button variant="outlined" sx={buttonStyles}>Status</Button>
          <Button variant="outlined" sx={buttonStyles}>Location</Button>
          <Button variant="outlined" sx={buttonStyles}>Year</Button>
          <Button variant="outlined" sx={buttonStyles}>Tags</Button>
          <Button variant="outlined" sx={buttonStyles}>Available to chat</Button>
          {/* <div className="flex gap-4 items-center">
            <span className="min-w-fit">1 Mil</span>
            <Slider
              defaultValue={1}
              min={1}
              max={10000}
              sx={{ color: "rgb(var(--global-color-primary))", width: "300px" }}
            />
            <span className="min-w-fit">10 Bil</span>
          </div> */}
          <Button variant="outlined" sx={buttonStyles}>Sort by</Button>
        </div>
      </div>

      {/* Startups list */}
      <div 
        className="grid gap-3" 
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridGap: '15px',
        }}
>          
        <style>
          {`
            @media (min-width: 768px) {
              div.grid {
                grid-template-columns: repeat(3, 1fr) !important;
              }
            }
          `}
        </style>
        {loading ? (
          <p>Loading startups...</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          startupsData.length > 0 ? (
            listStartups // Render the list of startups
          ) : (
            <p>No startups found</p>
          )
        )}
      </div>

      {/* Fetch startups data */}
      <FlexibleDataFetcher
        endpoint="innovations"
        params={{ per_page: 15 }} // Adjust for pagination
        onSuccess={handleDataFetchSuccess}
        onError={handleDataFetchError}
      />
    </div>
  );
}

// Button styles to keep it consistent
const buttonStyles = {
  fontFamily: "Urbanist, sans-serif",
  border: "1px solid rgb(255, 255, 255, .3)",
  color: "rgb(255, 255, 255, .3)"
};

export default Startups;
