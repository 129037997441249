import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Home from './pages/home';
import Startups from './pages/startups';
import Startup from './pages/startup';
import Blog from './pages/blog';
import ArticlePage from './components/post';
import Sidebar from './components/sidebar';
import './global.css';
import { Box } from '@mui/material';
import Chats from './pages/chats';
import AccountInformation from './pages/accountInformations';
import CompanyDetails from './pages/companyDetails';
import BillingDetails from './pages/billingDetails';
import Dashboard from './pages/dashboard';
import StartupList from './pages/startupList';
import StartupAnalysis from './pages/startupAnalysis';
// import FlexibleDataFetcher from './components/flexibileDataFetcher';
import Auth from './components/auth';
import UserList from './pages/userList';
import ExpoRivaCompetition from './pages/expoRivaCompetitionSubmition';
import CompanyMembers from './pages/companyMembers';
import Articles from './pages/articles';
import InboxMessages from './pages/inboxMessages';
import ManageKeywords from './pages/manageKeywords';
import ManageTags from './pages/manageTags';
import RolesAndPermissions from './pages/rolesAndPermissions';
import RetailhubTeam from './pages/retailhubTeam';
import PlanTypes from './pages/planTypes';
import Prices from './pages/prices';
import PaymentPlans from './pages/paymentPlans';
import PaymentReceipts from './pages/paymentReceipts';

function App() {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Header che prende tutta la larghezza della pagina */}
        <Header />

        {/* Container per la sidebar e il contenuto */}
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {/* Sidebar fissa o temporanea */}
          <Sidebar />

          {/* Contenuto principale */}
          <Box component="main" sx={{ flexGrow: 1, overflowX: "hidden" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/startups" element={<Startups />} />
              <Route path="/startupList" element={<StartupList />} />
              <Route path="/userList" element={<UserList />} />
              <Route path="/expoRivaCompetition" element={<ExpoRivaCompetition />} />
              <Route path="/startupAnalysis" element={<StartupAnalysis />} />
              <Route path="/startups/startup/:id" element={<Startup />} />
              <Route path="/chats" element={<Chats />} />
              <Route path="/account" element={<AccountInformation />} />
              <Route path="/companyDetails" element={<CompanyDetails />} />
              <Route path="/companyMembers" element={<CompanyMembers />} />
              <Route path="/billingDetails" element={<BillingDetails />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/inboxMessages" element={<InboxMessages />} />
              <Route path="/manageKeywords" element={<ManageKeywords />} />
              <Route path="/manageTags" element={<ManageTags />} />
              <Route path="/rolesAndPermissions" element={<RolesAndPermissions />} />
              <Route path="/retailhubTeam" element={<RetailhubTeam />} />
              <Route path="/planTypes" element={<PlanTypes />} />
              <Route path="/prices" element={<Prices />} />
              <Route path="/paymentPlans" element={<PaymentPlans />} />
              <Route path="/paymentReceipts" element={<PaymentReceipts />} />
              
              {/* Rotta per la pagina dell'articolo */}
              <Route path="/article/:id" element={<ArticlePage />} />

              {/* Rotta per ottenere il token */}
              <Route path="/auth" element={<Auth />} />

            </Routes>
          </Box>
        </Box>
      </div>
    </Router>
  );
}

export default App;