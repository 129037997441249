import * as React from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';

const companies = [
  {
    id: 0,
    name: "Grocery"
  },
  {
    id: 1,
    name: "Mass Merchandising"
  },
  {
    id: 2,
    name: "Consumer Goods"
  },
  {
    id: 3,
    name: "Hospitality"
  },
  {
    id: 4,
    name: "Food Services"
  },
  {
    id: 5,
    name: "Fashion"
  },
  {
    id: 6,
    name: "Luxury"
  },
  {
    id: 7,
    name: "Design"
  },
  {
    id: 8,
    name: "Home"
  },
  {
    id: 9,
    name: "Office & DIY" 
  },
  {
    id: 10,
    name: "Consumer Electronics"
  },
  {
    id: 11,
    name: "Pharma"
  }
]

const permissions = [
  {
    id: 0,
    name: "Read"
  },
  {
    id: 1,
    name: "Write"
  },
  {
    id: 2,
    name: "Execute"
  },
  {
    id: 3,
    name: "All"
  }
]

export default function NewUserDialog() {
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstname] = React.useState("");
  const [lastName, setLastname] = React.useState("");
  const [individuals, setIndividuals] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [companiesSelected, setSelectedCompanies] = React.useState([]);
  const [permissionsSelected, setPermissions] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'firstname':
        setFirstname(event.target.value)
        break;
      case 'lastname':
        setLastname(event.target.value)
        break;
      case 'individuals':
        setIndividuals(event.target.value)
        break;
      case 'companyName':
        setCompanyName(event.target.value)
        break;
      case 'expiryDate':
        setExpiryDate(event.target.value)
        break;
      case 'password':
        setPassword(event.target.value)
        break;
      case 'email':
        setEmail(event.target.value)
        break;
      default:
        setFirstname(event.target.value)
        break;
    }
  }
  
  const handleChangeCompanies = (event) => {
    setSelectedCompanies(event.target.value)
  }
  
  const handleChangePermissions = (event) => {
    setPermissions(event.target.value)
  }

  return (
    <React.Fragment>
      <StandardButton 
        text="Add new user"
        width="300px" 
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new user</h1>
            <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
              <StandardTextfield label='Firstname' value={firstName} onChange={(e) => {handleChange(e, 'firstname')}} />
              <StandardTextfield label='Lastname' value={lastName} onChange={(e) => {handleChange(e, 'lastname')}} />
              <StandardSelect 
                label="Companies" 
                value={companiesSelected} 
                items={companies} 
                onChange={handleChangeCompanies} 
              />
              <StandardTextfield label='Individuals' value={individuals} onChange={(e) => {handleChange(e, 'individuals')}} />
              <StandardTextfield label='Company name' value={companyName} onChange={(e) => {handleChange(e, 'companyName')}} />
              <StandardTextfield label='Password' value={password} onChange={(e) => {handleChange(e, 'password')}} />
              <StandardTextfield label='Expiry Date' value={expiryDate} onChange={(e) => {handleChange(e, 'expiryDate')}} />
              <StandardSelect 
                label="Permission" 
                value={permissionsSelected} 
                items={permissions} 
                onChange={handleChangePermissions} 
              />
              <StandardTextfield label='Email' value={email} onChange={(e) => {handleChange(e, 'email')}} />
              <div className='flex items-center gap-2 text-white'>
                <Checkbox sx={{color: "white"}}/>
                <span>Set reminder <br />(This will set reminder for admin)</span>
              </div>
            </div>            
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={handleClose} autoFocus={true} text="Submit" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}