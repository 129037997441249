import { Button } from "@mui/material";

export default function StandardButton({variant, text, onClick, autoFocus, width, type, className}) {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      sx={{
        fontFamily: "Urbanist, sans-serif", 
        border: !!type && type === "secondary" ? "1px solid rgb(255, 255, 255, 0.3)" : "1px solid rgb(var(--global-color-primary))", 
        color: !variant || variant === "filled" ? "black" : !!type && type === "secondary" ? "rgb(255, 255, 255, 0.3)" :"rgb(var(--global-color-primary))",
        backgroundColor: variant === "outlined" ? "transparent" : "rgb(var(--global-color-primary))",
        width: !!width ? width : "auto"
      }}
      autoFocus={autoFocus}
      className={className}
    >
      {text}
    </Button>
  )
}