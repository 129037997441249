import React, { useState } from 'react';
import BlogSection from '../components/blogSection';
import FlexibleDataFetcher from '../components/flexibileDataFetcher';
import { Typography, Button } from '@mui/material';

function Blog() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle data fetch success for news
  const handleNewsFetchSuccess = (data) => {
    if (data && data.data) {
      setNewsData(data.data); // Save the fetched news data
    }
    setLoading(false);
  };

  // Handle data fetch error
  const handleDataFetchError = (err) => {
    setError(err.message || 'Failed to fetch data');
    setLoading(false);
  };

  return (
    <div className="flex flex-row flex-wrap gap-5" style={{ padding: '25px' }}>
      <div className="bg-black-l p-5 flex flex-col flex basis-full gap-5 justify-center items-center rounded-lg relative">
        <h2>Last news</h2>

        {/* Handle loading and error states */}
        {loading && <Typography>Loading articles...</Typography>}
        {error && <Typography color="error">{error}</Typography>}

        {/* Display the BlogSection with news articles */}
        {!loading && !error && (
          <div className="flex flex-row gap-3">
            <BlogSection articles={newsData} postsPerPage={9} showPagination={false} /> {/* Set postsPerPage to 9 */}
          </div>
        )}

        {/* Fetch the news data */}
        <FlexibleDataFetcher
          endpoint="news"
          params={{ per_page: 9 }} // Fetch 9 articles from the API
          onSuccess={handleNewsFetchSuccess}
          onError={handleDataFetchError}
        />
      </div>
    </div>
  );
}

export default Blog;
