import React, { useState } from 'react';
import Dots from '../assets/images/Dots.png';
import { Box, Typography, Button } from '@mui/material';
import StartupsCard from "../components/startupCard";
import BlogSection from '../components/blogSection';
import FlexibleDataFetcher from '../components/flexibileDataFetcher';

function Home() {
  const [startupsData, setStartupsData] = useState([]);
  const [newsData, setNewsData] = useState([]); // Add state for news
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle data fetch success for startups
  const handleStartupsFetchSuccess = (data) => {
      if (data && data.data) {
          setStartupsData(data.data);
      }
      setLoading(false);
  };

  // Handle data fetch success for news
  const handleNewsFetchSuccess = (data) => {
      if (data && data.data) {
          setNewsData(data.data); // Save the fetched news data
      }
      setLoading(false);
  };

  // Handle data fetch error
  const handleDataFetchError = (err) => {
      setError(err.message || 'Failed to fetch data');
      setLoading(false);
  };


    // Map the startupsData to StartupsCard components
    const listStartups = startupsData.map((s) => (
        <StartupsCard
            key={s.id}
            name={s.name}
            description={s.description.replace(/<\/?[^>]+(>|$)/g, "")} // Remove HTML tags
            logo={s.logo_url || "https://dev.retailhub.ai/default-logo.png"} // Default logo if none
            countryCode={s.country?.name || "US"} // Fallback to "US" if no country
            tags={s.taxonomies?.tags?.length > 0 
                ? s.taxonomies.tags.map((tag, index) => ({
                    id: index,
                    label: tag.name || "N/A",
                    variant: "outlined"
                  }))
                : [{ id: 0, label: "No tags", variant: "outlined" }]} // Fallback for no tags
            link={`/startups/startup/${s.id}`} 
        />
    ));

    return (
        <div className="flex flex-row flex-wrap gap-5" style={{ padding: '25px' }}>
            {/* Box Completa Account */}
            <div className="bg-black-l p-5 flex basis-full flex-col md:flex-row gap-1 justify-between items-center rounded-lg relative">
                <div className="topIcon">&times;</div>
                <div className="flex-col">
                    <h3>Complete your account!</h3>
                    <p>For a better experience, you must complete the account verification.</p>
                </div>
                <div className="flex-col">
                    <Box sx={{ padding: '20px 25px' }}>
                      <Button variant="contained" fullWidth sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight:700, textTransform:'capitalize' }}>
                          Complete now &rarr;
                      </Button>
                    </Box>
                </div>
            </div>

            {/* Doppio Box */}
            <div className="bg-black-l p-10 flex basis-full md:basis-[calc(50%-10px)] flex-col gap-1 justify-between items-start rounded-lg relative">
                <div className="topIcon">
                    <img src={Dots} alt="dots" style={{ width: '50px' }}/>
                </div>
                <h2>Hello, Explorer! You can now start exploring Retail Hub.</h2>
                <p>Intelligent search for business owners, enterprises, and tech enthusiasts.</p>
                <Button variant="contained" sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight:700, textTransform:'capitalize' }}>
                    Start now &rarr;
                </Button>
            </div>

            <div className="bg-black-l p-10 flex basis-full md:basis-[calc(50%-10px)] flex-col gap-1 justify-between items-start rounded-lg relative">
                <div className="topIcon">
                    <img src={Dots} alt="dots" style={{ width: '50px' }}/>
                </div>
                <h2>Discover our new AI powered services!</h2>
                <p><a href="/">AI Navigator</a> is an interactive chat ready to resolve your doubts and help you navigate the platform.</p>
                <p><a href="/">Advisory Program</a> allows you to compare a series of startups that match your search to carry out a complete evaluation and generate reports.</p>
                <div className="flex flex-row gap-5">
                    <Button variant="contained" sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight:700, textTransform:'capitalize' }}>
                        Try AI Navigator &rarr;
                    </Button>
                    <Button variant="contained" sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight:700, textTransform:'capitalize' }}>
                        Try Advisory Program &rarr;
                    </Button>
                </div>
            </div>

            {/* Box Startup */}
            <div className="bg-black-l p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
                <h2>Startups</h2>
                <div
                  className="grid gap-3"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '15px',
                  }}
                >
                  <style>
                    {`
                      @media (min-width: 768px) {
                        div.grid {
                          grid-template-columns: repeat(3, 1fr);
                        }
                      }
                    `}
                  </style>
                  {loading ? (
                    <p>Loading startups...</p>
                  ) : error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                  ) : (
                    startupsData.length > 0 ? (
                      listStartups
                    ) : (
                      <p>No startups found</p>
                    )
                  )}
                </div>
            </div>

            {/* Box Articoli */}
            <div className="bg-black-l p-5 flex basis-full flex-col gap-5 justify-center items-center rounded-lg relative">
                <h2>Last news</h2>
                <div className="flex flex-row gap-3">
                    <BlogSection articles={newsData} showPagination={false} />
                </div>
                <Button component="a" href="/blog" variant="contained" sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight: 700, textTransform: 'capitalize' }}>
                    View All &rarr;
                </Button>
            </div>

            {/* Fetch startups and news data */}
            <FlexibleDataFetcher
                endpoint="innovations"
                params={{ per_page: 6 }}
                onSuccess={handleStartupsFetchSuccess}
                onError={handleDataFetchError}
            />

            <FlexibleDataFetcher
                endpoint="news"
                params={{ per_page: 3 }}
                onSuccess={handleNewsFetchSuccess}
                onError={handleDataFetchError}
            />
        </div>
    );
}

export default Home;
