import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';

const sectors = [
  {
    id: 0,
    label: "Grocery",
    children: [
      {
        label: "Mass Merchandising",
        id: 1,
        children: [
          {
            label: "Consumer Goods",
            id: 1
          }
        ]
      },
      {
        label: "Bakery",
        id: 2,
        children: [
          {
            label: "Consumer Goods",
            id: 1
          },
          {
            label: "Hospitality",
            id: 2
          }
        ]
      }
    ]
  },
  {
    id: 1,
    label: "Mass Merchandising",
    children: [
      {
        label: "Grocery",
        id: 1
      }
    ]
  },
  {
    id: 2,
    label: "Consumer Goods",
    children: [
      {
        label: "Grocery",
        id: 1
      }
    ]
  },
  {
    id: 3,
    label: "Hospitality",
    children: [
      {
        label: "Growing Plants",
        id: 1
      }
    ]
  },
  {
    id: 4,
    label: "Food Services",
    children: [
      {
        label: "Groceries",
        id: 1
      }
    ]
  },
  {
    id: 5,
    label: "Fashion",
    children: [
      {
        label: "IED",
        id: 1
      }
    ]
  },
  {
    id: 6,
    label: "Luxury",
    children: [
      {
        label: "Don't know",
        id: 1
      }
    ]
  },
  {
    id: 7,
    label: "Design",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 8,
    label: "Home",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 9,
    label: "Office & DIY",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 10,
    label: "Consumer Electronics",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 11,
    label: "Pharma",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 12,
    label: "Beauty",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 13,
    label: "Pet Care",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 14,
    label: "Automotive",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 15,
    label: "Sports",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 16,
    label: "Leisure",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 17,
    label: "Telecom",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 18,
    label: "Banking",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 19,
    label: "Insurance",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 20,
    label: "Financial Services",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 21,
    label: "Media and Entertainment",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 22,
    label: "Services",
    children: [
      {
        label: "",
        id: 1
      }
    ]
  },
  {
    id: 23,
    label: "Training",
    children: [
      {
        label: "Pippo",
        id: 1
      }
    ]
  },
  {
    id: 24,
    label: "Human Resources",
    children: [
      {
        label: "Ciao",
        id: 1
      }
    ]
  }
]

export default function SectorFilterDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedSectorCategories, setSelectedSectorCategories] = React.useState([]);
  const [selectedSectorSubCategories, setSelectedSectorSubCategories] = React.useState([]);
  const handleSectorSelection = (sector) => {
    setSelectedSectorSubCategories([])
    if(!!sector.children && sector.children.length > 0)
      setSelectedSectorCategories(sector.children)
  }
  const handleSubSectorSelection = (sector) => {
    if(!!sector.children && sector.children.length > 0)
      setSelectedSectorSubCategories(sector.children)
  }

  return (
    <React.Fragment>
      <Button 
        variant="outlined" 
        onClick={handleClickOpen}
        sx={{
          fontFamily: "Urbanist, sans-serif", 
          border: open ? "1px solid rgb(var(--global-color-primary))" : "1px solid rgb(255, 255, 255, .3)", 
          color: open ? "rgb(var(--global-color-primary))" : "rgb(255, 255, 255, .3)"
        }}
      >
        Sector
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 xl:grid-cols-3'>
            <div className='flex flex-col gap-2 justify-start items-start'>
              <h1 className="text-xl">Sectors</h1>
              {
                sectors.map((s) =>
                  <Button 
                    sx={{
                      color: "white", 
                      padding: "0", 
                      textTransform: "none", 
                      justifyContent: "start",
                      fontFamily: "Urbanist, sans-serif"
                    }} 
                    key={s.id}
                    onClick={() => {handleSectorSelection(s)}}
                  >{s.label}</Button>
                )
              }
            </div>
            {
              selectedSectorCategories.length > 0 && 
              <div className='flex flex-col gap-2 justify-start items-start'>
                <h1 className="text-xl">Sector Categories</h1>
                {
                  selectedSectorCategories.map((s) =>
                    <Button 
                      sx={{
                        color: "white", 
                        padding: "0", 
                        textTransform: "none", 
                        justifyContent: "start",
                        fontFamily: "Urbanist, sans-serif"
                      }} 
                      key={s.id}
                      onClick={() => {handleSubSectorSelection(s)}}
                    >{s.label}</Button>
                  )
                }
              </div>
            }
            {
              selectedSectorSubCategories.length > 0 && 
              <div className='flex flex-col gap-2 justify-start items-start'>
                <h1 className="text-xl">Sector Sub Categories</h1>
                {
                  selectedSectorSubCategories.map((s) =>
                    <Button 
                      sx={{
                        color: "white", 
                        padding: "0", 
                        textTransform: "none", 
                        justifyContent: "start",
                        fontFamily: "Urbanist, sans-serif"
                      }} 
                      key={s.id}
                    >{s.label}</Button>
                  )
                }
              </div>
            }
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Reset" width={"200px"} type={"secondary"}/>
          <StandardButton variant='outlined' onClick={handleClose} autoFocus={true} text="Apply" width={"200px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}