import React from "react";
import PaginatedTable from '../components/paginatedTable'
import NewDiscoveryChip from "../components/newDiscoveryChip";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import NewPaymentPlanDialog from "../components/newPaymentPlanDialog";

const items = [
  { id: 1, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 2, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 3, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 4, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 5, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 6, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 7, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 8, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 9, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 10, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 11, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 12, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 13, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 14, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 15, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 16, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 17, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
  { id: 18, planName: 'Single Investor', stripeProduct: 'Single Investor', planType: 140, userType: "Admin", members: 140, price: 100, trialPeriod: '7 days' },
];

export default function PaymentPlans() {
  const [rows, setRows] = React.useState(items);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const header = [
    { field: 'planName', headerName: 'Plan Name', width: 200 },
    { field: 'stripeProduct', headerName: 'Stripe Product', width: 200 },
    { field: 'planType', headerName: 'Plan Type', type: "number", width: 130 },
    { field: 'userType', headerName: 'User Type', width: 130 },
    { field: 'members', headerName: 'Members', type: "number", width: 130 },
    { field: 'price', headerName: 'Price', type: "number", width: 130 },
    { field: 'trialPeriod', headerName: 'Trial Period', width: 200 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    }
  ];

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (!!editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-[100vh]">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full bg-[#191919] rounded-2xl">
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full relative">
          <NewDiscoveryChip />
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Payment Plans</h1>
            <NewPaymentPlanDialog />
          </div>
          <PaginatedTable header={header} items={items} />
        </div>
      </div>
    </div>
  )
}