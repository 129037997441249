import * as React from 'react';
import { Dialog, DialogActions, DialogContent} from '@mui/material';
import StandardButton from './standardButton';
import StandardTextfield from './standardTextfield';
import StandardSelect from './standardSelect';

const paymentPeriods = [
  {
    id: 0,
    name: "Now"
  },
  {
    id: 1,
    name: "30 days"
  },
  {
    id: 2,
    name: "3 months"
  },
  {
    id: 3,
    name: "6 months"
  },
  {
    id: 4,
    name: "12 months"
  }
]

const userRoles = [
  {
    id: 0,
    name: "Base User"
  },
  {
    id: 1,
    name: "Admin"
  }
]

export default function NewPaymentPlanDialog() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [userRole, setUserRole] = React.useState([]);
  const [groupName, setGroupName] = React.useState("");
  const [paymentPeriod, setPaymentPeriod] = React.useState([]);
  const [trialDays, setTrialDays] = React.useState(1);
  const [productName, setProductName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, field) => {
    switch (field) {
      case 'name':
        setName(event.target.value)
        break;
      case 'type':
        setType(event.target.value)
        break;
      case 'price':
        setPrice(event.target.value)
        break;
      case 'userRole':
        setUserRole(event.target.value)
        break;
      case 'groupName':
        setGroupName(event.target.value)
        break;
      case 'paymentPeriod':
        setPaymentPeriod(event.target.value)
        break;
      case 'trialDays':
        setTrialDays(event.target.value)
        break;
      case 'productName':
        setProductName(event.target.value)
        break;
      default:
        setName(event.target.value)
        break;
    }
  }

  return (
    <React.Fragment>
      <StandardButton 
        text="Add new payment plan"
        width="300px" 
        variant="filled"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: "16px !important",
            overflow: 'hidden',
            backgroundColor: "#191919"
          }
        }}
      >
        <DialogContent sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919"}}>
          <div className='flex flex-col gap-10 justify-center items-center'>
            <h1 className="text-6xl">Add new payment plan</h1>
            <div className='flex flex-wrap gap-4 lg:grid lg:grid-cols-2 w-full'>
              <StandardTextfield label='Name' value={name} onChange={(e) => {handleChange(e, 'name')}} />
              <StandardTextfield label='Type' value={type} onChange={(e) => {handleChange(e, 'type')}} />
              <StandardTextfield label='Price' value={price} onChange={(e) => {handleChange(e, 'price')}} />
              <StandardSelect 
                items={userRoles} 
                value={userRole} 
                label={"User Role"}
                onChange={(e) => {handleChange(e, 'userRole')}}
              />
              <StandardTextfield label='Group Name' value={groupName} onChange={(e) => {handleChange(e, 'groupName')}} />
              <StandardSelect 
                items={paymentPeriods} 
                value={paymentPeriod} 
                label={"Payment period"}
                onChange={(e) => {handleChange(e, 'paymentPeriod')}}
              />
              <StandardTextfield label='Trial Days' value={trialDays} onChange={(e) => {handleChange(e, 'trialDays')}} />
              <StandardTextfield label='Product name on Stripe side' value={productName} onChange={(e) => {handleChange(e, 'productName')}} />
            </div>            
          </div>
        </DialogContent>
        <DialogActions sx={{fontFamily: "Urbanist, sans-serif", backgroundColor: "#191919", paddingTop: "40px", justifyContent: 'center', gap: '50px'}}>
          <StandardButton variant='outlined' onClick={handleClose} text="Cancel" width={"300px"} type={"secondary"}/>
          <StandardButton onClick={handleClose} autoFocus={true} text="Submit" width={"300px"}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}