import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Box, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Importa le icone PNG
import dashboard from '../assets/icons/dashboard.png';
import scout_startups from '../assets/icons/scout_startups.png';
import saved from '../assets/icons/saved.png';
import chats from '../assets/icons/chats.png';
import ai_navigator from '../assets/icons/ai_navigator.png';
import advisory_program from '../assets/icons/advisory_program.png';
import account from '../assets/icons/account.png';
import StandardButton from './standardButton';

function Sidebar() {
  const [userAdmin, setUserAdmin] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (isOpen) => () => {
    setMobileOpen(isOpen);
  };

  const toggleUser = () => {
    setUserAdmin(!userAdmin)
  }

  const drawer = (
    <Box sx={{height: "100%"}} >
      {
        !userAdmin &&
        <Box>
          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Home
          </Typography>

          <List className={'SidebarMenuList'} sx={{paddingLeft:'25px'}}>
            <ListItem button component={Link} to="/">
              <Box component="img" src={dashboard} alt="Home" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Home"/>
            </ListItem>
            <ListItem button component={Link} to="/startups">
              <Box component="img" src={scout_startups} alt="Startups" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Startups" />
            </ListItem>
            <ListItem button component={Link} to="/startups">
              <Box component="img" src={saved} alt="Saved" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Saved" />
            </ListItem>
            <ListItem button component={Link} to="/chats">
              <Box component="img" src={chats} alt="Chats" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Chats" />
            </ListItem>
            <ListItem button component={Link} to="/startups">
              <Box component="img" src={ai_navigator} alt="AI Navigator" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="AI Navigator" />
            </ListItem>
            <ListItem button component={Link} to="/startups">
              <Box component="img" src={advisory_program} alt="Advisory Program" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Advisory Program" />
            </ListItem>
            <ListItem button component={Link} to="/account">
              <Box component="img" src={account} alt="Account" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Account" />
            </ListItem>      
          </List>

          <Typography variant="h6" sx={{ paddingTop: '25px', paddingBottom: '5px', paddingLeft: '25px', color: 'white', fontFamily: 'Urbanist, sans-serif' }}>
            Sectors
          </Typography>

          <Box
            sx={{
              maxHeight: '200px',  // Altezza fissa del contenitore scrollabile
              overflowY: 'auto',
              paddingLeft: '25px',
              pr: 2,  // padding-right per far spazio alla scrollbar personalizzata                    
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#C5FF55',   // Colore del pollice della scrollbar
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#2B2C2D',  // Colore dello sfondo della scrollbar
              },
            }}
          >
            <List className={'SidebarMenuList'}>
              <ListItem button component={Link} to="/">
                <Box component="img" src={dashboard} alt="Grocery" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
                <ListItemText primary="Grocery"/>
              </ListItem>    
              <ListItem button component={Link} to="/">
                <Box component="img" src={dashboard} alt="Retail" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
                <ListItemText primary="Retail"/>
              </ListItem>
              <ListItem button component={Link} to="/">
                <Box component="img" src={dashboard} alt="Automotive" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
                <ListItemText primary="Automotive"/>
              </ListItem>
              <ListItem button component={Link} to="/">
                <Box component="img" src={dashboard} alt="Logistics" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
                <ListItemText primary="Logistics"/>
              </ListItem>
              <ListItem button component={Link} to="/">
                <Box component="img" src={dashboard} alt="Finance" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
                <ListItemText primary="Finance"/>
              </ListItem>
            </List>
          </Box>

          <Box sx={{ padding: '20px 25px', display: "flex", flexDirection: "column", gap: "20px" }}>
            <Button variant="contained" fullWidth sx={{ fontSize: '16px', fontFamily: 'Urbanist, sans-serif', backgroundColor: '#C5FF55', color: '#191919', fontWeight:700, textTransform:'capitalize' }}>
              Advanced Search &rarr;
            </Button>
            <StandardButton               
              text="Log as Admin"
              onClick={toggleUser}
            />
          </Box>
        </Box>
      }
      {
        userAdmin &&
        <Box
          sx={{
            height: "100%"
          //   maxHeight: '100vh',  // Altezza fissa del contenitore scrollabile
          //   overflowY: 'auto'
          }}
        >
          <List className={'SidebarMenuList'} sx={{paddingLeft:'25px', height: "100%"}}>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Dashboard
            </Typography>
            <ListItem button component={Link} to="/dashboard">
              <Box component="img" src={dashboard} alt="Dashboard" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Dashboard"/>
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Startups
            </Typography>
            <ListItem button component={Link} to="/startupList">
              <Box component="img" src={scout_startups} alt="Startup List" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Startup List"/>
            </ListItem>
            <ListItem button component={Link} to="/startupAnalysis">
              <Box component="img" src={scout_startups} alt="Startup Analysis" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Startup Analysis" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Users
            </Typography>
            <ListItem button component={Link} to="/userList">
              <Box component="img" src={account} alt="User List" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="User List" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Expo Riva Competition
            </Typography>
            <ListItem button component={Link} to="/expoRivaCompetition">
              <Box component="img" src={account} alt="Competition Form" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Competition Form" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Content
            </Typography>
            <ListItem button component={Link} to="/articles">
              <Box component="img" src={account} alt="Articles" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Articles" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Messages
            </Typography>
            <ListItem button component={Link} to="/inboxMessages">
              <Box component="img" src={account} alt="Inbox" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Inbox" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Categories
            </Typography>
            <ListItem button component={Link} to="/manageKeywords">
              <Box component="img" src={account} alt="Manage Keywords" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Manage Keywords" />
            </ListItem>
            <ListItem button component={Link} to="/manageTags">
              <Box component="img" src={account} alt="Manage Tags" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Manage Tags" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Administration
            </Typography>
            <ListItem button component={Link} to="/rolesAndPermissions">
              <Box component="img" src={account} alt="Roles and Permissions" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Roles and Permissions" />
            </ListItem>
            <ListItem button component={Link} to="/retailhubTeam">
              <Box component="img" src={account} alt="RetailHub Team" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="RetailHub Team" />
            </ListItem>
            <Typography sx={{ paddingTop: '12px', paddingBottom: '5px', paddingLeft: '12px', color: 'white', fontFamily: 'Urbanist, sans-serif', fontWeight: 'bold', fontSize: '1.1rem' }}>
              Subscription Plans
            </Typography>
            <ListItem button component={Link} to="/planTypes">
              <Box component="img" src={account} alt="Plan Types" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Plan types" />
            </ListItem>
            <ListItem button component={Link} to="/prices">
              <Box component="img" src={account} alt="Prices" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Prices" />
            </ListItem>
            <ListItem button component={Link} to="/paymentPlans">
              <Box component="img" src={account} alt="Payment plans" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Payment plans" />
            </ListItem>
            <ListItem button component={Link} to="/paymentReceipts">
              <Box component="img" src={account} alt="Payment receipts" sx={{ width: 24, marginRight: 2, '&:hover': { opacity: 0.7, },}} />
              <ListItemText primary="Payment receipts" />
            </ListItem>
            <StandardButton 
              text="Log as User"
              onClick={toggleUser}
            />
          </List>
        </Box>
      }
    </Box>
  );

  return (
    <>
      <>
        {/* Sidebar fissa su schermi > sm */}

        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : true}
          onClose={toggleDrawer(false)}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              position: 'relative',
              backgroundColor: '#231F20',
              width: 250,
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Icona di menu solo su mobile */}
          {isMobile && (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{
                position: 'absolute',
                top: 25,
                right: 10,
                color: '#C5FF55',
                '&:hover': {
                  backgroundColor: 'rgba(197, 255, 85, 0.1)',
                }
              }}
            >
              <MenuIcon sx={{ color: '#C5FF55' }} />
            </IconButton>
          )}
      </>
    </>
  );
}

export default Sidebar;
