import React from "react";
import { Card, CardMedia, CardContent, Typography, Chip } from "@mui/material";
import CountryFlag from "./countryFlag";

function StartupsCard({ name, description, logo, countryCode, tags, link }) {
  return (
    <a href={link} className="snap-start hover:no-underline"> {/* Use the link prop here */}
      <Card 
        className="flex flex-col p-5 border border-solid border-white border-opacity-30"
        sx={{color: 'white', backgroundColor: 'transparent', height: "100%"}}
      >
        <div className="flex gap-4 items-center">
          <CardMedia
            className="!w-20 h-20 rounded-lg"
            component="img"
            image={logo}
            alt={name}
          />
          <CardContent>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily: "Urbanist, sans-serif" }}>
              {name}
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: "Urbanist, sans-serif", maxHeight: "100px", overflow: 'hidden' }}>
              {description}
            </Typography>
          </CardContent>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-wrap w-full gap-2 items-center">
            {tags.map(tag => 
              <Chip 
                label={tag.label}
                variant={tag.variant}
                sx={{
                  fontFamily: "Urbanist, sans-serif", 
                  border: "1px solid rgb(255, 255, 255, .3)", 
                  backgroundColor: tag.variant === "filled" ? "rgb(var(--global-color-primary))" : "transparent",
                  color: tag.variant === "filled" ? "black" : "white"
                }}
                key={tag.id}
              />
            )}
          </div>
          <div className="flex flex-col gap-2 items-center">
            <span className="font-semibold">Country</span>
            <CountryFlag countryCode={countryCode} />
          </div>
        </div>
      </Card>
    </a>
  );
}

export default StartupsCard;
